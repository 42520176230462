import { useEffect, useState } from "react";

export const events: Array<EventPayload> = [
  {
    date: new Date("2024-06-06T19:00:00+02:00"),
    resultsUrl:
      "https://www.davengo.com/event/result/sauerlaender-firmenlauf-2024/",
  },
  {
    date: new Date("2025-06-26T19:00:00+02:00"),
  },
];

export const useLatestPastEvent = () => {
  const [latestPastEvent, setLatestPastEvent] = useState<
    EventPayload | undefined
  >();

  useEffect(() => {
    const now = new Date();

    setLatestPastEvent(
      events.findLast(({ date, resultsUrl }) => date < now && resultsUrl),
    );
  }, []);

  return latestPastEvent;
};

export const useUpcomingEvent = () => {
  const latestPastEvent = useLatestPastEvent();

  const [upcomingEvent, setUpcomingEvent] = useState<
    EventPayload | undefined
  >();

  useEffect(() => {
    const now = new Date();

    const upcoming = events.find(({ date }) => date > now);

    setUpcomingEvent(
      !latestPastEvent ||
        new Date(latestPastEvent.date.getTime() + 7 * 24 * 60 * 60 * 1000) < now
        ? upcoming
        : undefined,
    );
  }, [latestPastEvent]);

  return upcomingEvent;
};
