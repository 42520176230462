import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Button from "@components/button";
import Container from "@components/container";
import Link from "@components/link";
import Hamburger from "@/components/hamburger";
import { useTheme } from "@/contexts/theme";
import { useUpcomingEvent } from "@/data/events";
import { cn } from "@/helpers/classNames";
import { useIsMedium } from "@/hooks/useMediaQuery";
import Logo from "@/layouts/partials/logo";

const Header: React.FC = () => {
  const upcomingEvent = useUpcomingEvent();

  const menuItems = [
    { to: "/", title: "Überblick" },
    { to: "/ablauf", title: "Ablauf" },
    { to: "/sponsoren", title: "Sponsoren & Partner" },
    { to: "/faq", title: "FAQ" },
  ];
  const { setHeaderHeight, headerHeight } = useTheme();
  const [displayMenu, setDisplayMenu] = useState(false);
  const headerRef = useRef<HTMLElement>(null);

  const isMobile = !useIsMedium();

  const calculateHeaderDimensions = useCallback(() => {
    if (headerRef?.current) setHeaderHeight(headerRef.current.clientHeight);
  }, [headerRef, setHeaderHeight]);

  useEffect(() => {
    window.addEventListener("resize", calculateHeaderDimensions);
    window.addEventListener("load", calculateHeaderDimensions);

    calculateHeaderDimensions();

    return () => {
      window.removeEventListener("resize", calculateHeaderDimensions);
      window.removeEventListener("load", calculateHeaderDimensions);
    };
  }, [calculateHeaderDimensions]);

  return (
    <header id="top" className="absolute inset-x-0 top-0 z-30" ref={headerRef}>
      <Container className="flex flex-wrap items-center justify-end py-base md:flex-nowrap">
        <div className="flex grow">
          <Link to={menuItems[0].to} className="rounded">
            <Logo
              className="w-auto h-12 xs:h-14 md:h-16 lg:w-auto lg:h-auto"
              aria-hidden="true"
            />
            <span className="sr-only">Sauerländer AOK Firmenlauf</span>
          </Link>
        </div>
        <AnimatePresence>
          {(isMobile ? displayMenu : true) && (
            <motion.nav
              initial={{
                opacity: 0,
                y: -30,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              exit={{
                opacity: 0,
                y: -30,
              }}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
              }}
              className={cn(
                "block -z-10 bg-black/75 backdrop-blur-xl shadow-2xl rounded-b-2xl fixed top-0 inset-x-0 md:static md:shadow-none md:bg-transparent md:rounded-none md:mr-gutter md:z-0 md:w-auto md:backdrop-blur-none",
              )}
              style={{ paddingTop: isMobile ? `${headerHeight}px` : 0 }}
            >
              <ul className="flex flex-col items-end p-md pb-lg md:p-0 md:flex-row md:items-center gap-gutter group">
                {menuItems.map((item) => (
                  <li key={item.title + item.to}>
                    <Link
                      activeClassName="border-b-2"
                      className="border-b-0 border-white pb-xxs group-hover:border-b-0 group-hover:hover:border-b-2"
                      to={item.to}
                      onClick={() => setDisplayMenu(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </motion.nav>
          )}
        </AnimatePresence>

        {upcomingEvent?.registerUrl && (
          <Button
            className="!hidden lg:!inline-block"
            to={upcomingEvent.registerUrl}
          >
            Anmelden
          </Button>
        )}
        <Hamburger
          className="my-xs ml-base md:hidden"
          onClick={() => setDisplayMenu(!displayMenu)}
          active={displayMenu}
        />
      </Container>
    </header>
  );
};

export default Header;
