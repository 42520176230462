import React from "react";
import { LazyMotion, domAnimation } from "framer-motion";
import Header from "@/layouts/partials/header";
import Footer from "@/layouts/partials/footer";
import "@resources/styles/app.css";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <LazyMotion features={domAnimation}>
    <div className="flex flex-col items-stretch min-h-screen">
      <Header />
      <div className="flex flex-col items-stretch justify-center flex-1">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  </LazyMotion>
);

export default Layout;
